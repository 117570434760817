import { gql } from "@apollo/client";

export const GET_PAYMENT_FROM_PROFILE = gql`
  query getPaymentFromProfile($paymentID: Int!) {
    getPayment(paymentID: $paymentID) {
      id
      amount {
        amount
        currency
      }
      resultCode
      errorValue
      datetime
      card {
        id
        number
        type
      }
    }
  }
`;
