import React from "react";

import PaymentResult from "@/components/profile/paymentResult";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

const PaymentResultPage = () => {
  if (!isBrowser()) {
    return null;
  }

  const urlParams = new URLSearchParams(window.location.search.replace("?", ""));
  const isSuccess = !!urlParams.get("success");
  const isFailure = !!urlParams.get("failure" || "fail");
  const paymentId = parseInt(urlParams.get("payment_id") ?? "", 10) ?? undefined;

  return <PaymentResult isSuccess={isSuccess} isFailure={isFailure} paymentId={paymentId} />;
};

export default PaymentResultPage;
