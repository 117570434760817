import moment from "moment";

/**
 * Возвращает краткие дату и время
 * @param timestamp
 * @returns Строка с датой формата "19.09.2005, 07:04"
 */
export const convertToDateAndTime = (timestamp: number): string =>
  new Date(timestamp).toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    hour: "2-digit",
    minute: "2-digit",
  });

/**
 * Возвращает "литературную" дату с годом (если не равен предыдущему)
 * @param timestamp
 * @returns Строка с датой формата "19 сентября 2005"
 */
export const convertToDateLiterary = (timestamp: number): string => {
  const date = new Date(timestamp);

  if (date.getFullYear() === new Date().getFullYear()) {
    return `${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
      month: "long",
      day: "numeric",
    })}`;
  }

  return `${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    month: "long",
    day: "numeric",
  })} ${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    year: "numeric",
  })}`;
};

/**
 * Возвращает дату с трёхбуквенным месяцем и годом
 * @param timestamp
 * @returns Строка с датой формата "19 сен 2005"
 */
export const convertToDateLiteraryShortened = (timestamp: number | string): string => {
  const date = new Date(timestamp);

  return `${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    day: "numeric",
  })} ${date
    .toLocaleDateString(process.env.GATSBY_LANGUAGE, {
      month: "short",
    })
    .substring(0, 3)} ${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    year: "numeric",
  })}`;
};

/**
 * Возвращает "литературную" дату с днём недели и годом (если не равен предыдущему)
 * @param timestamp
 * @returns Строка с датой формата "19 сентября 2005, понедельник"
 */
export const convertToDateAndWeekdayLiterary = (timestamp: number): string => {
  const date = new Date(timestamp);

  if (date.getFullYear() === new Date().getFullYear()) {
    return `${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
      month: "long",
      day: "numeric",
    })}, ${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
      weekday: "long",
    })}`;
  }

  return `${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    month: "long",
    day: "numeric",
  })} ${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    year: "numeric",
  })}, ${date.toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    weekday: "long",
  })}`;
};

/**
 * Возвращает время вида 1:23
 * @param timestamp
 * @returns
 */
export const convertToTime = (timestamp: number): string =>
  new Date(timestamp).toLocaleTimeString(process.env.GATSBY_LANGUAGE, {
    hour: "numeric",
    minute: "2-digit",
  });

/**
 * Возвращает дату вида 01.05.2022
 * @param timestamp
 * @returns
 */
export const convertToDate = (timestamp: number): string =>
  new Date(timestamp).toLocaleDateString(process.env.GATSBY_LANGUAGE, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

/**
 * Возвращает дату вида 1.05.22
 * @param timestamp
 * @returns
 */
export const convertToShortDate = (timestamp: number): string =>
  new Date(timestamp).toLocaleDateString("de-DE", {
    day: "numeric",
    month: "2-digit",
    year: "2-digit",
  });

/**
 * Возвращает литературную строку с временным промежутком
 * @param timestamp1
 * @param timestamp2
 * @returns
 */
export const convertToDurabilityLiterary = (timestamp1: number, timestamp2: number): string => {
  const diffMinutes = Math.ceil(Math.abs(new Date(timestamp2).getTime() - new Date(timestamp1).getTime()) / 1000 / 60);
  if (diffMinutes / 60 >= 1) {
    return `${Math.floor(diffMinutes / 60)} ч ${diffMinutes % 60} мин`;
  }
  return `${diffMinutes % 60} мин`;
};

/**
 * Возвращает метку времени (timestamp) из строки с датой и временем
 * @param {string} dateString - Строка с датой и временем в формате "YYYY-MM-DD HH:mm:ss.SSSSSS Z"
 * @returns {number} - Метка времени в миллисекундах
 */
export const convertToTimestamp = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM-DD HH:mm:ss.SSSSSS Z");
  const timestamp = date.valueOf();
  return timestamp;
};
